<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5 justify-content-between">
      <div class="col-md-3" v-if="!readOnly && puo_utilizzare_pulsanti_sezioni">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#modal_add-tesserato-unasocieta"
            id="OpenAddTesserato"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi tesserato
          </button>
          <AddTesseratoUnaSocieta
            :id_societa="id_societa"
            :stagione="stagione"
            @getTesserati="$emit('getTesserati')"
          />
        </div>
      </div>
      <div
        class="col-md-3 text-center"
        v-if="!readOnly && puo_utilizzare_pulsanti_sezioni"
      >
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-center"
            @click="stampaTessereArray()"
          >
            <i class="bi bi-printer fs-6 text-gray-700 me-1"></i>stampa tessere
            (elenco visualizzato)
          </button>
        </div>
      </div>
      <div :class="!readOnly ? 'col-3 text-center' : 'col-9'">
        <div v-if="puo_utilizzare_pulsanti_sezioni">
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-center"
            data-bs-toggle="modal"
            data-bs-target="#modal_esporta_tesserati"
          >
            <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
            filtrata
          </button>
        </div>
      </div>
      <ModalEsportaTesseratiUnaSocieta :id_societa="id_societa" />
      <div class="col-md-3">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          Tesserati di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        tesserati_list_ente.length === 0 &&
        loaded &&
        (status == 200 || status == 204) === true
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
      <!-- <span class="sr-only">Loading...</span> -->
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="tesserati_list_ente"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsTesseratoEnte"
        @current-change="setCurrentPageTesseratoEnte"
      >
        <template v-slot:cell-cognome="{ row: data }">
          <div class="tab-long">
            <Popper
              v-if="
                data.colore_permesso_di_soggiorno == 'red' ||
                data.colore_permesso_di_soggiorno == 'orange'
              "
              arrow
              :content="
                data.colore_permesso_di_soggiorno == 'red'
                  ? `Permesso di soggiorno scaduto il ${data.scadenza_permesso_soggiorno}`
                  : data.colore_permesso_di_soggiorno == 'orange'
                  ? `Permesso di soggiorno in scadenza il ${data.scadenza_permesso_soggiorno}`
                  : ''
              "
            >
              <i class="bi bi-info-circle-fill me-1 text-center"></i>
            </Popper>
            <router-link
              :to="{
                name: `anagrafica-persona`,
                params: {
                  id: data.id_persona,
                  fromSocieta: true,
                  id_societa: societa.id,
                },
              }"
              :class="
                data.colore == 'red' ||
                data.colore_permesso_di_soggiorno == 'red'
                  ? 'text-danger'
                  : data.colore == 'orange' ||
                    data.colore_permesso_di_soggiorno == 'orange'
                  ? 'text-orange'
                  : ''
              "
            >
              {{ data.nominativo_completo }}</router-link
            >
          </div>
        </template>
        <template v-slot:cell-data_nascita="{ row: data }"
          ><div
            class=""
            :class="
              data.colore == 'red' || data.colore_permesso_di_soggiorno == 'red'
                ? 'text-danger'
                : data.colore == 'orange' ||
                  data.colore_permesso_di_soggiorno == 'orange'
                ? 'text-orange'
                : ''
            "
          >
            {{ data.data_nascita }}
          </div>
        </template>
        <template v-slot:cell-cod_tessera="{ row: data }">
          <div
            class=""
            :class="
              data.colore == 'red' || data.colore_permesso_di_soggiorno == 'red'
                ? 'text-danger'
                : data.colore == 'orange' ||
                  data.colore_permesso_di_soggiorno == 'orange'
                ? 'text-orange'
                : ''
            "
          >
            {{ data.cod_tessera }}
          </div>
        </template>
        <!-- <template v-slot:cell-tipo_tessera="{ row: data }">
          <div
            class=""
            :class="
                data.colore == 'red' || data.colore_permesso_di_soggiorno == 'red'
                  ? 'text-danger'
                  : data.colore == 'orange' || data.colore_permesso_di_soggiorno == 'orange'
                  ? 'text-orange'
                  : ''
              "
          >
            {{ data.tipo_tessera }}
          </div>
        </template>-->
        <template v-slot:cell-categoria="{ row: data }"
          ><div
            v-if="data.categoria"
            class="tab-long"
            :class="
              data.colore == 'red' || data.colore_permesso_di_soggiorno == 'red'
                ? 'text-danger'
                : data.colore == 'orange' ||
                  data.colore_permesso_di_soggiorno == 'orange'
                ? 'text-orange'
                : ''
            "
          >
            {{ data.categoria }}
          </div>
          <span
            v-else
            :class="
              data.colore == 'red' || data.colore_permesso_di_soggiorno == 'red'
                ? 'text-danger'
                : data.colore == 'orange' ||
                  data.colore_permesso_di_soggiorno == 'orange'
                ? 'text-orange'
                : ''
            "
          >
            -
          </span>
        </template>
        <template v-slot:cell-classifica_attuale="{ row: data }"
          ><div
            class="text-center"
            :class="
              data.colore == 'red' || data.colore_permesso_di_soggiorno == 'red'
                ? 'text-danger'
                : data.colore == 'orange' ||
                  data.colore_permesso_di_soggiorno == 'orange'
                ? 'text-orange'
                : ''
            "
          >
            {{ data.classifica_attuale }}
            <div
              v-if="data.classifica_attuale != '-'"
              class="btn-group"
              @click="
                getRankList(data.id_persona, data.id_disciplina);
                selectedPersona = data.nominativo_completo;
              "
            >
              <button class="btn btn-sm p-1">
                <a
                  href="#"
                  class="navi-link"
                  data-bs-toggle="modal"
                  data-bs-target="#dettaglio_classifica"
                >
                  <i
                    :class="
                      data.colore == 'red' ||
                      data.colore_permesso_di_soggiorno == 'red'
                        ? 'text-danger'
                        : data.colore == 'orange' ||
                          data.colore_permesso_di_soggiorno == 'orange'
                        ? 'text-orange'
                        : ''
                    "
                    class="bi bi-info-circle fs-6 p-1"
                  ></i>
                </a>
              </button>
            </div></div
        ></template>
        <template v-slot:cell-data_certificato="{ row: data }"
          ><div
            class="text-center"
            :class="
              data.colore == 'red' || data.colore_permesso_di_soggiorno == 'red'
                ? 'text-danger'
                : data.colore == 'orange' ||
                  data.colore_permesso_di_soggiorno == 'orange'
                ? 'text-orange'
                : ''
            "
          >
            {{ data.data_certificato }}
          </div></template
        >
        <template v-slot:cell-data_emissione="{ row: data }"
          ><div
            class="text-center"
            :class="
              data.colore == 'red' || data.colore_permesso_di_soggiorno == 'red'
                ? 'text-danger'
                : data.colore == 'orange' ||
                  data.colore_permesso_di_soggiorno == 'orange'
                ? 'text-orange'
                : ''
            "
          >
            {{ data.data_emissione }}
          </div>
        </template>
        <template v-slot:cell-data_scadenza="{ row: data }"
          ><div
            class="text-center"
            :class="
              data.colore == 'red' || data.colore_permesso_di_soggiorno == 'red'
                ? 'text-danger'
                : data.colore == 'orange' ||
                  data.colore_permesso_di_soggiorno == 'orange'
                ? 'text-orange'
                : ''
            "
          >
            {{ data.data_scadenza }}
          </div>
        </template>
        <template v-slot:cell-data_stampa="{ row: data }"
          ><div
            class="text-center"
            :class="
              data.colore == 'red' || data.colore_permesso_di_soggiorno == 'red'
                ? 'text-danger'
                : data.colore == 'orange' ||
                  data.colore_permesso_di_soggiorno == 'orange'
                ? 'text-orange'
                : ''
            "
          >
            {{ data.data_stampa }}
          </div>
        </template>
        <template v-slot:cell-approvata="{ row: data }"
          ><div v-if="data.approvata" class="text-center">
            <i
              class="bi bi-check fw-bold fs-2"
              :class="
                data.colore == 'red' ||
                data.colore_permesso_di_soggiorno == 'red'
                  ? 'text-danger'
                  : data.colore == 'orange' ||
                    data.colore_permesso_di_soggiorno == 'orange'
                  ? 'text-orange'
                  : ''
              "
            ></i>
          </div>
          <div v-if="!data.approvata" class="text-center">
            <i class="bi bi-x fw-bold fs-2 text-danger"></i>
          </div>
        </template>
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <button
              v-if="!readOnly && puo_utilizzare_pulsanti_sezioni"
              class="btn btn-sm dropdown"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <a
                href="#"
                v-if="!data.approvata && isEnabled('fnSocietaTesseratiApprova')"
                @click="
                  approvaTesseramento(
                    data.id,
                    data.id_societa,
                    data.id_persona,
                    data.id_disciplina
                  )
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check text-dark fs-3"></i>
                      <span class="text-dark ps-1 fs-7"> Approva </span>
                    </div>
                  </div>
                </li></a
              >
              <router-link
                :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id_persona}`"
                v-if="!readOnly"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-eye text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Visualizza Tesserato
                      </span>
                    </div>
                  </div>
                </li></router-link
              >
              <a
                href="#"
                v-if="data.valida && !readOnly"
                @click="stampaTessera(data.id, data.id_persona)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-printer text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7" type="button">
                        Stampa Tessera
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modal_edit_tesseramento_una_soc"
                @click="
                  selectedIdTesserato = data.id;
                  onlyView = true;
                "
                v-if="!readOnly && (data.approvata || data_emissione)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-eye text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Visualizza Tesseramento
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                @click="
                  selectedIdTesserato = data.id;
                  onlyView = false;
                "
                data-bs-toggle="modal"
                data-bs-target="#modal_edit_tesseramento_una_soc"
                v-if="!readOnly && (data.approvata || data_emissione)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-pen text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Modifica Tesseramento
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="isEnabled('fnSocietaTesseratiDeleteTesseramento')"
                data-bs-toggle="modal"
                data-bs-target="#modal_edit_scuola"
                @click="
                  deletePopup(data.id, data.cod_tessera);
                  selectedIdTesserato = data.id;
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-trash text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Cancella Tesseramento
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="
                  data.tipo_tessera == 'Atleta' &&
                  !readOnly &&
                  (data.approvata || data_emissione)
                "
                data-bs-toggle="modal"
                data-bs-target="#dettaglio_attivita_sportiva"
                @click="
                  getAttivitaSportiva(data.cod_tessera);
                  selectedPersona = data.nominativo_completo;
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="">
                        <img
                          src="/media/fit-media/297262.svg"
                          width="17"
                          height="17"
                          class="mb-1"
                      /></i>
                      <span class="text-dark ps-1 fs-7">
                        Attività sportiva
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <!-- isEnabled('fnSocietaTesseratiQrTesserato') && -->
              <a
                href="#"
                v-if="data.approvata || data_emissione"
                data-bs-toggle="modal"
                data-bs-target="#modal_visualizza_tessera"
                @click="
                  selectedTessera = data.cod_tessera;
                  selectedCrypt = data.crypt;
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-qr-code text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Lettura QR Code </span>
                    </div>
                  </div>
                </li></a
              >
            </ul>
          </div>
        </template>
      </Datatable>

      <EditTesseratoUnaSocieta
        :id_tesserato="selectedIdTesserato"
        :onlyView="onlyView"
        @getTesserati="
          $emit('getTesserati');
          selectedIdTesserato = null;
        "
      />
      <DettaglioAttivitaSportiva
        :nominativo_persona="selectedPersona"
        :attivita_sportiva_list="attivita_sportiva_list"
        :loadingDetail="loadingDetail"
        :error="error"
      />
      <ModalTesseraQr
        :cod_tessera="selectedTessera"
        :crypt="selectedCrypt"
        @resetTessera="
          selectedTessera = null;
          selectedCrypt = null;
        "
      />
    </div>
    <br />
  </div>
  <!-- <div v-if="loaded && !readOnly && puo_utilizzare_pulsanti_sezioni">
    <button
      type="button"
      class="btn btn-light text-gray-700 fs-6 text-start"
      @click="CentriEstivi(societa.cod_affiliazione)"
    >
      <i class="">
        <img
          src="/media/fit-media/tennisball_116397.svg"
          width="17"
          height="17"
          class="mb-1" /></i
      >Centri estivi per allievi inviati
    </button>
  </div>
  <div v-for="(tipologia, index) in richieste_tipologie" :key="index">
    <button
      v-if="
        isEnabled('fnSocietaTesseratiBatch') && puo_utilizzare_pulsanti_sezioni
      "
      type="button"
      class="btn btn-light text-gray-700 fs-6 text-start"
      data-bs-toggle="modal"
      data-bs-target="#modal_upload_batch"
      @click="tipologia_batch = tipologia"
    >
      <i class="bi bi-upload fs-6 text-gray-700"></i>caricamento Batch
      {{ tipologia.label }}
    </button>
  </div>
  <UploadTessereBatch
    :tipologia_batch="tipologia_batch"
    :id_societa="societa.id"
  />
  <DettaglioClassifica
    :nominativo_persona="selectedPersona"
    :rank_list="rank_list"
    :loadingDetail="loadingDetail"
  /> -->
</template>

<script>
import AddTesseratoUnaSocieta from "./AddTesseratoUnaSocieta.vue";
import EditTesseratoUnaSocieta from "./EditTesseratoUnaSocieta.vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
// import UploadTessereBatch from "@/components/components-fit/importazioni/UploadTessereBatch.vue";

import { ref, computed, watch, getCurrentInstance } from "vue";
import {
  deleteTesserato,
  approvaTesseramentoSoc,
  stampaTessereTutte,
  stampaTessereTutteArray,
  preparaStampa,
} from "@/requests/tesseratoUnaSocRequests";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { viewRankList } from "@/requests/ranking";
// import DettaglioClassifica from "@/components/components-fit/ranking/ModalDettaglioClassifica.vue";
import DettaglioAttivitaSportiva from "@/components/components-fit/tesserati/tesserati-societa/ModalAttivitaSportiva.vue";
import { viewAttivitaSportiva } from "@/requests/personaRequests";
import isEnabled from "@/composables/isEnabled.js";
import { useStore } from "vuex";

import ModalTesseraQr from "@/components/components-fit/tesserati/tesserati-societa/ModalTesseraQr.vue";
import ModalEsportaTesseratiUnaSocieta from "./ModalEsportaTesseratiUnaSocieta.vue";

import Popper from "vue3-popper";

export default {
  name: "tableTesseratiSocieta",
  emits: ["getTesserati", "resetFilters"],
  components: {
    AddTesseratoUnaSocieta,
    EditTesseratoUnaSocieta,
    Datatable,
    Loading,
    // UploadTessereBatch,
    // DettaglioClassifica,
    DettaglioAttivitaSportiva,
    ModalTesseraQr,
    ModalEsportaTesseratiUnaSocieta,
    Popper,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const tipologia_batch = ref(null);

    //header tabella
    const tableHeader = ref([
      {
        name: "Tesserato",
        key: "cognome",
      },
      {
        name: "Codice Tessera",
        key: "cod_tessera",
      },
      /* {
        name: "Tipo Tessera",
        key: "tipo_tessera",
      }, */
      {
        name: "Categoria",
        key: "categoria",
      },
      {
        name: "Rank",
        key: "classifica_attuale",
        align: "center",
      },
      {
        name: "Scadenza certificato",
        key: "data_certificato",
        align: "center",
      },
      {
        name: "Data emissione",
        key: "data_emissione",
        align: "center",
      },
      {
        name: "Data scadenza",
        key: "data_scadenza",
        align: "center",
      },
      {
        name: "Data stampa",
        key: "data_stampa",
        align: "center",
      },
      {
        name: "Appr.",
        key: "approvata",
        align: "center",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const setFetchRowsTesseratoEnte = (e) => {
      store.commit("setFetchRowsTesseratoEnte", e);
      emit("getTesserati");
    };
    const setCurrentPageTesseratoEnte = (page) => {
      store.commit("setCurrentPageTesseratoEnte", page);
      emit("getTesserati");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnTesseratoEnte", columnName);
      store.commit("setSortOrderTesseratoEnte", order);
      emit("getTesserati");
    };

    const selectedIdTesserato = ref(null);
    const onlyView = ref(false);
    const isLoading = ref(false);

    const deletePopup = (id, cod_tessera) => {
      Swal.fire({
        title: "Sei sicuro di voler eliminare il tesserato?",
        text: "Non potrai piu recuperarlo",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Si",
        customClass: {
          cancelButton: "btn fw-bold btn-light-danger",
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then((e) => {
        if (e.isDismissed) {
          return;
        } else {
          isLoading.value = true;
          deleteTesserato(id, cod_tessera, stagione.value).then((res) => {
            isLoading.value = false;
            if (res.status != 200) {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              return;
            }
            alertSuccess(res.data.results[0].result);
            emit("getTesserati");
          });
        }
      });
    };

    const approvaTesseramento = (
      id_Tesseramento_Da_Approvare,
      id_Societa,
      id_Persona,
      id_Disciplina
    ) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Il tesseramento verrà approvato.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Approva",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await approvaTesseramentoSoc(
            id_Tesseramento_Da_Approvare,
            id_Societa,
            id_Persona,
            id_Disciplina
          ).then((res) => {
            if (res.status == 200) {
              alertSuccess("Tesseramento approvato correttamente");
              emit("getTesserati");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const stampaTessera = async (id_tesseramento, id_persona) => {
      isLoading.value = true;
      await stampaTessereTutte(
        id_tesseramento,
        props.societa.id,
        id_persona,
        stagione.value,
        0,
        1
      ).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          console.log(res);
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    };

    const stampaTessere = () => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Procedendo verranno stampate tutte le tessere della società",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Stampa",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await stampaTessereTutte(
            "",
            props.societa.id,
            "",
            stagione.value,
            1,
            1
          ).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              console.log(res);
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };

    const id_societa = ref(props.societa.id);

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      getTipologieBatch();
    });

    const richieste_tipologie = computed(() =>
      store.getters.getStateFromName("richieste_tipologie")
    );

    const getTipologieBatch = () => {
      store.dispatch("setStoreData", {
        keys: { id_societa: props.societa.id, stagione: stagione.value },
        apiLink: globalApi.TIPOLOGIE_BATCH_LIST,
      });
    };
    getTipologieBatch();

    const tesserati_list_ente = computed(() =>
      store.getters.getStateFromName("resultstesserati_list_ente")
    );

    const tessereArray = ref([]);
    const item_id = ref(null);
    const stampaTessereArray = async () => {
      tessereArray.value = [];
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Procedendo verranno stampate tutte le tessere visualizzate nella lista (escluse le tessere che hanno subito un upgrade).",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Stampa",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          tesserati_list_ente.value.forEach((element) => {
            if (element.valida) {
              let tessereObj = {};
              tessereObj.id = element.id;
              tessereObj.id_persona = element.id_persona;
              tessereObj.cod_tessera = element.cod_tessera;
              tessereObj.id_societa = element.id_societa;
              tessereArray.value.push(tessereObj);
            }
          });
          isLoading.value = true;
          await preparaStampa(tessereArray.value, stagione.value).then(
            (res) => {
              if (res.status == 200) {
                item_id.value = res.data.item_id;
                isLoading.value = false;
                stampaTessereTutteArray(item_id.value);
              } else {
                isLoading.value = false;
                alertFailed(
                  res.data.message
                    ? res.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
              }
            }
          );
        }
      });
    };

    const selectedPersona = ref(null);

    const loadingDetail = ref(false);
    const rank_list = ref(null);
    const getRankList = (id_persona, id_disciplina) => {
      loadingDetail.value = true;
      viewRankList(id_persona, id_disciplina).then((res) => {
        loadingDetail.value = false;
        if (res.status == 200) {
          rank_list.value = res.data.results;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "dettaglio_classifica"
          );
        }
      });
    };

    const error = ref(false);

    const attivita_sportiva_list = ref(null);
    const getAttivitaSportiva = (tessera) => {
      error.value = false;
      loadingDetail.value = true;
      viewAttivitaSportiva(tessera, stagione.value).then((res) => {
        loadingDetail.value = false;
        if (res.status == 200) {
          attivita_sportiva_list.value = res.data.subscriptions;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "dettaglio_attivita_sportiva"
          );
          error.value = true;
        }
      });
    };

    const selectedTessera = ref("");
    const selectedCrypt = ref("");

    const CentriEstivi = (codiceAffiliazione) => {
      console.log(codiceAffiliazione);
      var win = window.open(
        "https://old-backoffice.federtennis.it/CE_Affiliati/accesso_CE_affiliati.asp?codicecircolo=" +
          codiceAffiliazione +
          "&user=cefit&password=cef1t2023",
        "_blank"
      );
      win.focus();
    };

    return {
      // prendo la risposta della richiesta della lista dallo store
      tesserati_list_ente: computed(() =>
        store.getters.getStateFromName("resultstesserati_list_ente")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedtesserati_list_ente")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordtesserati_list_ente")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statustesserati_list_ente")
      ),
      // elementi utili per configurazione del componente "Datatable"
      currentPage: computed(() => store.getters.currentPageTesseratoEnte),
      rowsToSkip: computed(() => store.getters.rowsToSkipTesseratoEnte),
      fetchRows: computed(() => store.getters.fetchRowsTesseratoEnte),
      sortColumn: computed(() => store.getters.sortColumnTesseratoEnte),
      sortOrder: computed(() => store.getters.sortOrderTesseratoEnte),
      setFetchRowsTesseratoEnte,
      setCurrentPageTesseratoEnte,
      setSortOrderColumn,
      tableHeader,
      selectedIdTesserato,
      onlyView,
      deletePopup,
      id_societa,
      stagione,
      isLoading,
      approvaTesseramento,
      stampaTessera,
      stampaTessere,
      tipologia_batch,
      richieste_tipologie,
      getTipologieBatch,
      stampaTessereArray,
      selectedPersona,
      getRankList,
      rank_list,
      loadingDetail,
      getAttivitaSportiva,
      attivita_sportiva_list,
      CentriEstivi,
      error,
      selectedTessera,
      selectedCrypt,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      puo_utilizzare_pulsanti_sezioni: computed(
        () => store.getters.puo_utilizzare_pulsanti_sezioni
      ),
    };
  },
};
</script>

<style scoped>
/* .dropdown-menu {
  min-width: 200px;
} */
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
